import Vue from 'vue';
import App from './App.vue';
import Vuelidate from 'vuelidate';

import '@/assets/scss/normalize.scss';
import '@/assets/scss/fonts.scss';

Vue.config.productionTip = false;
Vue.use(Vuelidate);

new Vue({
  render: h => h(App)
}).$mount('#app');
