var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('transition',{attrs:{"name":"loader"}},[(_vm.isLoading)?_c('Loader'):_vm._e()],1),_c('Header',{staticClass:"header_poll",attrs:{"isGreen":_vm.isGreen,"isOpenMenu":_vm.isOpenMenu,"isMobile":_vm.isMobile,"getme":_vm.getme,"config":_vm.config},on:{"toggle-menu":() => _vm.toggleMenu(),"open-login-popup":function($event){_vm.isOpenPopup = true;
      _vm.popupName = 'phone';
      _vm.isLoginAction = true;}}}),_c('Primary',{attrs:{"acceptance":_vm.getme.acceptance,"acceptanceAutoPayment":_vm.getme.acceptanceAutoPayment,"acceptAd":_vm.acceptAdValue,"config":_vm.config,"getme":_vm.getme,"checkCookie":_vm.checkCookie},on:{"request":_vm.request,"set-popup":_vm.setPopup,"acceptance-change":_vm.changeAception,"acceptance-ad":_vm.changeAd}}),_c('Footer',{attrs:{"config":_vm.config,"getme":_vm.getme}}),(_vm.isOpenPopup)?_c('Popup',{attrs:{"slotName":_vm.popupName},on:{"close-popup":function($event){_vm.isOpenPopup = false;
      _vm.phoneNotFound = false;}},scopedSlots:_vm._u([{key:"phone",fn:function(){return [_c('PopupLogin',{attrs:{"common":_vm.config.dictionary.common,"phoneNotFound":_vm.phoneNotFound},on:{"request-login":_vm.requestLogin,"clear-field":_vm.clearField}})]},proxy:true},{key:"acceptance",fn:function(){return [_c('PopupAcceptance',{attrs:{"common":_vm.config.dictionary.common}})]},proxy:true},{key:"sms",fn:function(){return [_c('PopupSMS',{attrs:{"common":_vm.config.dictionary.common,"wrongCode":_vm.getme.wrongCode},on:{"apply-sms":code => {
            _vm.isLoginAction
              ? _vm.applySmsQuestionLogin(code)
              : _vm.applySmsQuestion(code);
          }}})]},proxy:true}],null,false,1179928771)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }