<template>
  <section class="wrapper">
    <StepCount :step="1" :class="{ bank_v: !checkCookie }" />
    <div class="container">
      <div class="step" :class="{ bank_v: !checkCookie }">
        <h6>{{ config.dictionary.primary.step }}</h6>
        <h2>{{ config.dictionary.primary.title }}</h2>
        <form class="form" @submit.prevent="submit">
          <div
            class="input-wrapper"
            :class="{
              invalid: $v.phone.$error,
              valid: !$v.phone.$invalid
            }"
          >
            <label class="label" for="phone">
              {{ config.dictionary.common.fields.phone }}
            </label>
            <IMaskComponent
              maxlength="16"
              class="input"
              inputmode="numeric"
              id="phone"
              :mask="'+{7}({9}00)000-00-00'"
              v-model.trim.lazy="$v.phone.$model"
              ref="phone"
              @blur="onTouchFunc('phone', $event.target.value)"
              @input="onResetFunc('phone')"
              @complete="onCompleteFunc('email')"
            />
            <small v-if="$v.phone.$error">
              {{ config.dictionary.common.fieldsError.errorPhone }}
            </small>
          </div>

          <div
            class="input-wrapper"
            :class="{
              invalid: $v.email.$error,
              valid: !$v.email.$invalid
            }"
          >
            <label class="label" for="email">
              {{ config.dictionary.common.fields.email }}
            </label>
            <input
              type="email"
              class="input"
              id="email"
              v-model.trim="$v.email.$model"
              ref="email"
              @blur="onTouchFunc('email', $event.target.value)"
              @input="onResetFunc('email')"
            />
            <small v-if="$v.email.$error">
              {{ config.dictionary.common.fieldsError.errorEmail }}
            </small>
          </div>
          <div class="checkbox-wrapper">
            <input
              type="checkbox"
              name="acceptance"
              id="acceptance"
              :checked="acceptance"
              @change="$emit('acceptance-change', $event)"
            />
            <label
              for="acceptance"
              class="checkbox-label"
              v-html="checkAcceptText"
            >
            </label>
          </div>
          <div
            class="checkbox-wrapper auto-payment"
            v-if="
              config.siteConfig.acceptanceAutoPayment !== undefined &&
                config.siteConfig.acceptanceAutoPayment.length &&
                !checkCookie
            "
          >
            <input
              type="checkbox"
              name="acceptanceAutoPayment"
              id="acceptance-auto-payment"
              :checked="acceptanceAutoPayment"
              @change="$emit('acceptance-change', $event)"
            />
            <label
              for="acceptance-auto-payment"
              class="checkbox-label"
              v-html="config.siteConfig.acceptanceAutoPayment"
            >
            </label>
          </div>

          <div class="checkbox-wrapper auto-payment" v-if="!checkCookie">
            <input
              type="checkbox"
              name="acceptAdInput"
              id="acceptAdInput"
              :checked="acceptAd"
              @change="$emit('acceptance-ad', $event)"
            />
            <label
              for="acceptAdInput"
              class="checkbox-label"
              v-html="'Соглашаюсь на получение рекламных материалов'"
            >
            </label>
          </div>

          <button type="submit" class="button">
            {{ config.dictionary.common.btnsText.continue }}
          </button>
        </form>
      </div>

      <div class="info" v-if="checkCookie">
        <h3>
          {{ config.dictionary.common.laonInfo.title }}
          <span class="timer_info" v-if="timerInfo !== null">
            Осталось <b>{{ timerInfo }}</b>
          </span>
        </h3>
        <RangeSlider
          :loanSumStart="getme.loanSum"
          :loanSumCalcStep="config.frontConfig.loanSumCalcStep"
          :loanSumMin="config.frontConfig.loanSumMin"
          :loanSumMax="config.frontConfig.loanSumMax"
          :loanTermStart="getme.loanTerm"
          :loanTermCalcStep="config.frontConfig.loanTermCalcStep"
          :loanTermMin="config.frontConfig.loanTermMin"
          :loanTermMax="config.frontConfig.loanTermMax"
          :rangeSlider="config.dictionary.common.rangeSlider"
        />
      </div>
    </div>
  </section>
</template>

<script>
import RangeSlider from '@/components/app/RangeSlider';
import StepCount from '@/pages/poll/components/StepCount';
import { timerPoll } from '@/utils/commonFuncs';
import { onComplete, onReset, onTouch } from '@/utils/helperField';
import Cookies from 'js-cookie';
import { IMaskComponent } from 'vue-imask';

import {
  email,
  maxLength,
  minLength,
  required
} from 'vuelidate/lib/validators';

export default {
  name: 'Primary',
  components: {
    StepCount,
    RangeSlider,
    IMaskComponent
  },
  props: [
    'acceptance',
    'acceptanceAutoPayment',
    'acceptAd',
    'config',
    'getme',
    'checkCookie',
    'isGreen'
  ],
  data() {
    return {
      timerInfo: '',
      phone: '',
      email: '',
      onCompleteFunc: onComplete,
      onResetFunc: onReset,
      onTouchFunc: onTouch,
      timerPoll
    };
  },
  mounted() {
    this.timerPoll(this);
  },
  validations: {
    phone: {
      required,
      minLength: minLength(16),
      maxLength: maxLength(16)
    },
    email: {
      email
    }
  },
  computed: {
    isAuth() {
      return Object.prototype.hasOwnProperty.call(this.getme.contactData, 'id');
    },
    checkAcceptText() {
      const _this = this;
      if (_this.checkCookie && _this.isAuth) {
        return _this.config.siteConfig.acceptanceTextCpa;
      } else if (_this.checkCookie) {
        return _this.config.siteConfig.acceptanceTextCpaFirst;
      } else {
        return _this.config.siteConfig.acceptanceText;
      }
    },
    checkCPA() {
      return Cookies.get('sbg-cpa');
    }
  },
  watch: {
    'getme.contactData'(contactData) {
      this.phone = contactData.phone;
      this.email = contactData.email;
    }
  },
  methods: {
    submit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      if (!this.acceptance) {
        this.$emit('set-popup', 'acceptance');
        return;
      }

      if (
        (Cookies.get('sbg-cpa') !== undefined ||
          Cookies.get('CPA') !== undefined) &&
        (!this.acceptance || !this.acceptanceAutoPayment)
      ) {
        return;
      } else if (
        !(
          Cookies.get('sbg-cpa') !== undefined ||
          Cookies.get('CPA') !== undefined
        ) &&
        !this.acceptance
      ) {
        return;
      }

      const formData = {
        phone: this.phone,
        email: this.email
      };

      this.$emit('request', formData);
    }
  }
};
</script>

<style lang="scss">
@import '~@/assets/scss/poll.scss';

.step {
  .checkbox-wrapper {
    margin-top: 40px;

    &.auto-payment {
      margin-top: 20px;
    }

    &:last-of-type {
      margin-bottom: 30px;
    }
  }
}

.range-block__slider {
  max-width: 340px;
  width: 100%;
  margin: 0 auto 30px;

  @media (max-width: 990px) {
    padding-top: 0;
    margin: 0 auto 20px;

    p,
    .slider-summ {
      display: none;
    }

    .slider-range-wrapper__summ {
      .slider-range-wrapper__block {
        display: flex;
      }
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
}
</style>
